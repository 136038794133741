<script setup>
const quotes = [
  {
    author: 'Samuel Johnson',
    quote: 'Language is the dress of thought.',
    link: 'https://en.wikipedia.org/wiki/Samuel_Johnson',
  },
  {
    author: 'Rita Mae Brown',
    quote: 'Language is the road map of a culture. It tells you where its people come from and where they are going.',
    link: 'https://en.wikipedia.org/wiki/Rita_Mae_Brown',
  },
  {
    author: 'Ludwig Wittgenstein',
    quote: 'The limits of my language mean the limits of my world.',
    link: 'https://en.wikipedia.org/wiki/Ludwig_Wittgenstein',
  },
  {
    author: 'Patrick Rothfuss',
    quote: 'Words have power. Words can light fires in the minds of men. Words can wring tears from the hardest hearts.',
    link: 'https://en.wikipedia.org/wiki/Patrick_Rothfuss',
  },
  {
    author: 'Rita Mae Brown',
    quote: 'Language exerts hidden power, like a moon on the tides.',
    link: 'https://en.wikipedia.org/wiki/Rita_Mae_Brown',
  },
  {
    author: 'Evelyn Waugh',
    quote: 'One forgets words as one forgets names. One\'s vocabulary needs constant fertilizing or it will die.',
    link: 'https://en.wikipedia.org/wiki/Evelyn_Waugh',
  },
  {
    author: 'John Stuart Mill',
    quote: 'Language is the light of the mind.',
    link: 'https://en.wikipedia.org/wiki/John_Stuart_Mill',
  },
  {
    author: 'Frantz Fanon',
    quote: 'To speak means to be in a position to use a certain syntax, to grasp the morphology of this or that language, but it means above all to assume a culture, to support the weight of a civilization.',
    link: 'https://en.wikipedia.org/wiki/Frantz_Fanon',
  },
  {
    author: 'Unknown',
    quote: 'The tongue has no bones, but is strong enough to break a heart. So be careful with your words.',
    link: '',
  },
  {
    author: 'Kushandwizdom',
    quote: 'Words are free. It\'s how you use them that may cost you.',
    link: '',
  },
  {
    author: 'Benjamin Lee Whorf',
    quote: 'Language shapes the way we think, and determines what we can think about.',
    link: 'https://en.wikipedia.org/wiki/Benjamin_Lee_Whorf',
  },
  {
    author: 'Unknown',
    quote: 'The power of language is not to be underestimated, for words are windows into our souls and weapons in our hands.',
    link: '',
  },
  {
    author: 'Unknown',
    quote: 'With language, you can move the world without lifting a finger.',
    link: '',
  },
]

const randomQuote = computed(() => {
  return quotes[Math.floor(Math.random() * quotes.length)]
})
</script>

<template>
  <blockquote class="relative z-10 lg:absolute lg:top-0 lg:right-12 lg:w-2/5 lg:float-right lg:text-right text-3xl font-serif">
    <div>"{{ randomQuote.quote }}"</div>
    <cite class="text-zinc-200 font-sans text-base">
      -<a v-if="randomQuote.link" :href="randomQuote.link" target="_blank">{{ randomQuote.author }}</a>
      <span v-else>{{ randomQuote.author }}</span>
    </cite>
  </blockquote>
</template>
